import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaFacebook, FaInstagram, FaTiktok, FaBars, FaTimes } from 'react-icons/fa';

// Main header wrapper
const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative;
`;

// Left section with logo
const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 60px;
  margin-right: 1rem;
`;

// Center section with navigation
const NavSection = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;

  @media (max-width: 768px) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.3s ease;

  &:hover {
    color: #7C6A46;
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    border-bottom: 1px solid #eee;
  }
`;

// Right section with booking button and social icons
const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const BookButton = styled.a`
  background-color: #7C6A46;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5A4E33;
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    margin-top: 1rem;

  }
`;

const SocialLink = styled.a`
  color: #333;
  font-size: 1.2rem;
  transition: color 0.3s ease;
  
  &:hover {
    color: #7C6A46;
  }
`;

// Mobile menu button
const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <HeaderWrapper>
      <LeftSection>
        <Logo src="/Images/logo.jpg" alt="London LA Beauty Logo" />
      </LeftSection>
      
      <NavSection isOpen={isMenuOpen}>
        <Nav>
          <NavLink to="/" onClick={() => setIsMenuOpen(false)}>Home</NavLink>
          <NavLink to="/services" onClick={() => setIsMenuOpen(false)}>Services</NavLink>
          <NavLink to="/about" onClick={() => setIsMenuOpen(false)}>About</NavLink>
        </Nav>
      </NavSection>

      <RightSection>
        <BookButton href="https://facesconsent.com/clinics/london-la-beauty-306208" target="_blank" rel="noopener noreferrer">
          Book Appointment
        </BookButton>
        <SocialLinks>
          <SocialLink href="https://www.facebook.com/p/London-la-beauty-100063829913713/" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </SocialLink>
          <SocialLink href="https://www.instagram.com/londonlabeauty/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </SocialLink>
          <SocialLink href="https://www.tiktok.com/@londonlabeauty" target="_blank" rel="noopener noreferrer">
            <FaTiktok />
          </SocialLink>
        </SocialLinks>
      </RightSection>

      <MenuButton onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </MenuButton>
    </HeaderWrapper>
  );
}

export default Header;