import React from 'react';
import styled from 'styled-components';
import { FaInstagram, FaTiktok, FaFacebookF, FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const AboutWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 6rem 5%;
  background-color: #F9F5F0;
`;

const PageTitle = styled.h1`
  text-align: center;
  font-size: 3.5rem;
  color: #333;
  margin-bottom: 3rem;
  font-family: 'Playfair Display', serif;
`;

const Section = styled.section`
  margin-bottom: 6rem;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #8B7D5B;
  margin-bottom: 2rem;
  font-family: 'Playfair Display', serif;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 1.5rem;
`;

const OwnerSection = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const OwnerImage = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
`;

const OwnerInfo = styled.div`
  flex: 1;
`;

const OwnerName = styled.h3`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
  font-family: 'Playfair Display', serif;
`;

const OwnerTitle = styled.h4`
  font-size: 1.5rem;
  color: #8B7D5B;
  margin-bottom: 1.5rem;
`;

const MapSection = styled.div`
  display: flex;
  gap: 4rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MapWrapper = styled.div`
  flex: 1;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
`;

const ContactInfo = styled.div`
  flex: 1;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const SocialLink = styled.a`
  color: #8B7D5B;
  font-size: 2rem;
  transition: color 0.3s ease;

  &:hover {
    color: #6A5E44;
  }
`;
function AboutPage() {
  return (
    <AboutWrapper>
      <PageTitle>About London LA Beauty</PageTitle>

      <Section>
        <Paragraph>
          At London LA Beauty, we don't just aim to provide the highest quality aesthetic treatments and services. We deliver unparalleled results with our expertise and precision, ensuring you look and feel your best. Every detail is meticulously cared for, giving you the confidence you deserve.
        </Paragraph>
        <Paragraph>
          We offer a full range of advanced facial treatments and body contouring services using state-of-the-art technology. Our cutting-edge services are carefully tailored to your unique needs and goals, from rejuvenating anti-wrinkle injections to transformative PDO thread lifts.
        </Paragraph>
        <Paragraph>
          Our clinic, located in Canning Town South, offers a comprehensive range of services designed to enhance your natural beauty and boost your confidence.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>Meet Our Expert</SectionTitle>
        <OwnerSection>
          <OwnerImage src="/Images/owner.jpg" alt="Suzan" />
          <OwnerInfo>
            <OwnerName>Suzan</OwnerName>
            <OwnerTitle>Healthcare Professional</OwnerTitle>
            <Paragraph>
              I am a skilled beauty, cosmetic, and personal care professional committed to providing clients with exceptional quality services and detailed care. With extensive training, including a Level 7 qualification in Aesthetics, I possess the relevant knowledge and practical skills to perform advanced procedures safely and competently. My client-centric approach ensures a comfortable and welcoming experience.
            </Paragraph>
          </OwnerInfo>
        </OwnerSection>
      </Section>

      <Section>
        <SectionTitle>Visit Us</SectionTitle>
        <MapSection>
          <MapWrapper>
            <iframe
              title="Location for London La Beauty"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.7463699698708!2d0.0095441156844613!3d51.51428667963534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a81a5854b105%3A0x3b4e1a8f1ec4c6d7!2s28%20Barking%20Rd%2C%20London%20E16%201EQ%2C%20UK!5e0!3m2!1sen!2sus!4v1627307782387!5m2!1sen!2sus"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </MapWrapper>
          <ContactInfo>
            <ContactItem>
              <FaMapMarkerAlt />
              <span>28 Barking Rd, London E16 1EQ</span>
            </ContactItem>
            <ContactItem>
              <FaPhone />
              <span>020 3754 8710</span>
            </ContactItem>
            <ContactItem>
              <FaEnvelope />
              <span>Londonlabeauty@hotmail.com</span>
            </ContactItem>
            <SocialLinks>
              <SocialLink href="https://www.instagram.com/londonlabeauty/" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </SocialLink>
              <SocialLink href="https://www.tiktok.com/@londonlabeauty" target="_blank" rel="noopener noreferrer">
                <FaTiktok />
              </SocialLink>
              <SocialLink href="https://www.facebook.com/p/London-la-beauty-100063829913713/" target="_blank" rel="noopener noreferrer">
                <FaFacebookF />
              </SocialLink>
            </SocialLinks>
          </ContactInfo>
        </MapSection>
      </Section>
    </AboutWrapper>
  );
}

export default AboutPage;