import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const IntroSection = styled.section`
  background-color: #F9F5F0;
  padding: 6rem 5%;
  text-align: center;
`;

const Title = styled.h1`
  font-family: 'Playfair Display', serif;
  font-size: 3.5rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-size: 1.8rem;
  color: #666;
  margin-bottom: 2rem;
  font-weight: 300;
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #444;
  max-width: 800px;
  margin: 0 auto 3rem;
  line-height: 1.8;
`;

const LearnMoreBtn = styled(Link)`
  background-color: #8B7D5B;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.1rem;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  margin-bottom: 3rem;

  &:hover {
    background-color: #6A5E44;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
`;

const VideoWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  border-radius: 10px;
  overflow: hidden;
`;

const ResponsiveIframe = styled.iframe`
  width: 100%;
  aspect-ratio: 16 / 9;
  border: none;
`;

function ClinicIntroduction() {
  return (
    <IntroSection>
      <Title>London La Beauty</Title>
      <Subtitle>Exceptional aesthetic treatments for unparalleled results and confidence</Subtitle>
      <Description>
        Suzan, the owner of London LA Beauty, is a highly trained beauty and cosmetic professional with a Level 7 qualification in Aesthetics. She delivers exceptional services and personalized care in a comfortable, welcoming environment.
      </Description>
      <LearnMoreBtn to="/about">Learn More About Suzan</LearnMoreBtn>
      <VideoWrapper>
        <ResponsiveIframe
          src="https://www.youtube.com/embed/gPP2j3z8RvM?si=n9PJCQ2VEgSm27mZ"
          title="London La Beauty Introduction"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </VideoWrapper>
    </IntroSection>
  );
}

export default ClinicIntroduction;