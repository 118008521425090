import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const images = [
  { src: '/Images/coverpage.jpg', alt: 'Medical treatment' },
  { src: '/Images/coverpage2.jpg', alt: 'Skin treatment' },
  { src: '/Images/coverpage3.jpg', alt: 'Body treatment' },
  { src: '/Images/coverpage4.jpg', alt: 'Hair treatment' },
 
  
];

const HeroWrapper = styled.section`
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  transition: background-image 1s ease-in-out;
`;

const HeroContent = styled.div`
  max-width: 800px;
  padding: 2rem;
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
`;

const HeroSubtitle = styled.p`
  font-size: 1.8rem;
  margin-bottom: 2rem;
  font-weight: 300;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
`;

const CTAButton = styled(Link)`
  background-color: #8B7D5B;
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-weight: bold;
  font-size: 1.2rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: #6A5E44;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
`;

const ImageText = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
`;

function HeroSection() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <HeroWrapper backgroundImage={images[currentIndex].src}>
      <HeroContent>
        <HeroTitle>Welcome to London La Beauty</HeroTitle>
        <HeroSubtitle>Experience the Art of Beauty, Cosmetic & Personal Care</HeroSubtitle>
        <CTAButton to="/services">Explore Our Services</CTAButton>
      </HeroContent>
      <ImageText>{images[currentIndex].text}</ImageText>
    </HeroWrapper>
  );
}

export default HeroSection;