import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import Modal from '../components/Modals';

const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: #F9F5F0;
`;

const MainContent = styled.main`
  padding: 6rem 5%;

  @media (max-width: 768px) {
    padding: 2rem 5%;
  }
`;

const PageTitle = styled.h1`
  text-align: center;
  margin-bottom: 4rem;
  font-size: 3.5rem;
  color: #333;
  font-family: 'Playfair Display', serif;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

`;

const CategorySection = styled.section`
  margin-bottom: 6rem;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }

`;

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const CategoryImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 3rem;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }

`;

const CategoryTitle = styled.h2`
  font-size: 2.5rem;
  color: #8B7D5B;
  font-family: 'Playfair Display', serif;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

const CategorySummary = styled.p`
  font-size: 1.2rem;
  color: #666;
  text-align: left;
  max-width: 800px;
  line-height: 1.8;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: center;
  }
`;

const TreatmentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

`;

const TreatmentCard = styled.div`
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const TreatmentTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin: 0;
  padding: 1.5rem;
  background-color: #F4E0B9;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 1rem;
  }
`;

const TreatmentDetails = styled.div`
  padding: 1.5rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }

`;

const TreatmentInfo = styled.p`
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
`;

const BeforeAfterSection = styled.section`
  margin-top: 6rem;
  padding: 4rem 0;
  background-color: #F4E0B9;
`;

const BeforeAfterTitle = styled.h2`
  text-align: center;
  font-size: 3rem;
  color: #333;
  margin-bottom: 3rem;
  font-family: 'Playfair Display', serif;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const BeforeAfterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  padding: 0 5%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
`;

const BeforeAfterCard = styled.div`
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const BeforeAfterImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const BeforeAfterCaption = styled.p`
  text-align: center;
  padding: 1.5rem;
  font-weight: 600;
  color: #333;
  font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CategorySummaryWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
`;

const LearnMoreButton = styled.button`
  background-color: #F4E0B9;
  color: #333;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  &:hover {
    background-color: #EAD2A4;
  }
`;

const getFirstSentence = (text) => {
  const match = text.match(/^.*?[.!?](?:\s|$)/);
  return match ? match[0].trim() : text;
};


const services = {
  "MAIN TREATMENTS": {
    image: "/Images/maintreatment2.jpg",
    summary: "Our comprehensive range of advanced aesthetic treatments with detailed information, procedures, and aftercare.",
    treatments: [
      {
        name: "DERMAL FILLERS",
        info: "Injectable treatments to restore volume, smooth lines, and enhance facial contours. Dermal fillers are versatile cosmetic enhancements that can address various signs of aging. They're primarily composed of hyaluronic acid, a substance naturally found in the body. Fillers can be used to plump lips, define cheekbones, smooth nasolabial folds, enhance jaw lines, and rejuvenate the under-eye area. The effects are immediate and can last from 6 to 18 months, depending on the specific product used and the area treated.",
        procedure: "After a thorough facial assessment, the practitioner cleanses the treatment area and may apply a topical anesthetic. Using a fine needle or cannula, the filler is carefully injected into specific areas of the face. The practitioner may gently massage the area to ensure even distribution. The entire process typically takes 30-60 minutes.",
        aftercare: "Avoid touching or applying pressure to the treated area for 6 hours. Use cold compresses to reduce swelling. Avoid strenuous exercise, alcohol, and excessive heat for 24 hours. Sleeping with your head elevated can help minimize swelling. If bruising occurs, arnica cream may be applied. Avoid facial treatments for 2 weeks post-procedure.",
        image: "/Images/dermalfillers.jpeg"
      },
      {
        name: "ANTI-WRINKLE INJECTION",
        info: "Botox treatments to reduce the appearance of fine lines and wrinkles. These injections use botulinum toxin to temporarily relax facial muscles, smoothing out dynamic wrinkles such as crow's feet, forehead lines, and frown lines. Beyond cosmetic applications, Botox can also be used to treat excessive sweating (hyperhidrosis), migraines, and jaw clenching (bruxism). Effects typically last 3-6 months, with optimal results visible after about 2 weeks.",
        procedure: "The practitioner identifies and marks the injection sites. The area is cleaned, and using a very fine needle, small amounts of botulinum toxin are injected into specific muscles. The number of injections depends on the areas being treated. The procedure is quick, usually taking about 10-15 minutes.",
        aftercare: "Remain upright for 4 hours after treatment. Avoid rubbing or massaging the treated area for 24 hours. Refrain from strenuous exercise for 24 hours. Avoid facials or saunas for 24 hours. Results typically appear within 3-7 days, with full effect visible after 2 weeks. Avoid alcohol consumption and blood-thinning medications for 24 hours post-treatment.",
        image:"/Images/MTANTIWRINKLEINJECTION.jpg"
      },
      {
        name: "PDO THREAD",
        info: "Non-surgical facelift and skin tightening using dissolvable threads. PDO (polydioxanone) threads are biocompatible sutures inserted under the skin to lift and tighten sagging areas. This treatment not only provides an immediate lifting effect but also stimulates collagen production over time. PDO threads can be used on various areas including the face, neck, and body. Results can last up to 18 months, with gradual improvement as collagen production increases.",
        procedure: "After applying local anesthesia, the practitioner inserts thin threads under the skin using a fine needle or cannula. The threads are positioned to lift and support sagging tissue. The number and pattern of threads vary depending on the treatment area and desired effect. The procedure usually takes 30-60 minutes.",
        aftercare: "Avoid excessive facial movements for 2 weeks. Sleep on your back with your head elevated for a few nights. Avoid dental treatments for 2 weeks. Refrain from high-impact exercise for 1-2 weeks. Some swelling and bruising is normal and should subside within a week. Avoid massages or facial treatments for 3 weeks. Apply cold compresses if swelling occurs.",
        image: "/Images/PDPthread.jpeg"
      },
      {
        name: "SUNEKOS",
        info: "Advanced skin rejuvenation treatment combining amino acids and hyaluronic acid. Sunekos is a patented formula that stimulates the production of collagen and elastin, key components for youthful skin. This treatment improves skin elasticity, hydration, and overall texture. It's particularly effective for addressing skin laxity, fine lines, and dark circles. Suitable for face, neck, and hands, Sunekos can provide visible improvements after 3-4 treatments, with results lasting several months.",
        procedure: "The treatment area is cleansed and disinfected. The Sunekos solution, containing hyaluronic acid and a patented formula of amino acids, is injected into the middle layer of the skin using a very fine needle. Multiple small injections are administered across the treatment area. A typical session lasts about 30 minutes.",
        aftercare: "Avoid touching or applying makeup to the treated area for 12 hours. Stay out of direct sunlight and avoid intense heat for 1 week. Refrain from strenuous exercise for 24 hours. Mild redness and swelling may occur but should subside quickly. A course of 3-4 treatments, 7-10 days apart, is usually recommended for optimal results. Maintain hydration by drinking plenty of water."
      },
      {
        name: "PROFHILO",
        info: "Innovative hyaluronic acid treatment for skin laxity and hydration. Profhilo is a unique, highly pure hyaluronic acid product that spreads under the skin, unlike traditional fillers. It boosts hydration and stimulates collagen and elastin production, resulting in improved skin quality and a 'remodeling' effect. Particularly effective for the face, neck, and hands, Profhilo can address skin laxity, fine lines, and overall skin quality. visible improvements are typically seen after two treatments, with results lasting up to 6 months.",
        procedure: "The practitioner identifies five key points on each side of the face - known as the 'BAP technique' (Bio Aesthetic Points). After cleansing, Profhilo is injected into these points using a very fine needle. The procedure is repeated after one month. Each session typically takes about 10-15 minutes.",
        aftercare: "Avoid applying makeup or other skincare products for 24 hours. Refrain from intense physical activity for 24 hours. Avoid extreme temperatures (saunas, ice packs) for a few days. Mild swelling or bruising may occur but should resolve within a few days. Full results are typically visible about 8 weeks after the second treatment. Stay hydrated and protect the skin from sun exposure."
      },
      {
        name: "SKIN BOOSTER",
        info: "Various treatments to improve skin hydration, texture, and overall quality. Skin boosters involve micro-injections of hydrating substances, often including hyaluronic acid, vitamins, minerals, and antioxidants. These treatments nourish the skin from within, improving hydration, elasticity, and overall skin quality. They can address fine lines, dull complexion, and uneven skin texture. Regular treatments can lead to a more radiant, smoother complexion and improved skin health.",
        procedure: "The skin is thoroughly cleansed and a topical anesthetic may be applied. Using a very fine needle or a special injection device, microinjections of the chosen skin booster solution (e.g., hyaluronic acid, vitamins, peptides) are administered across the treatment area. The procedure usually takes 20-30 minutes.",
        aftercare: "Avoid touching the treated area for 6 hours. Do not apply makeup for 12 hours. Stay out of direct sunlight and avoid intense heat for 1 week. Mild redness and small bumps at injection sites are normal and should subside within 24-48 hours. A series of treatments, typically 3-4 sessions spaced 2-4 weeks apart, is often recommended. Maintain a good skincare routine and stay hydrated for optimal results."
      },
      {
        name: "PRP FACE/HAIR RESTORATION",
        info: "Platelet-Rich Plasma therapy to rejuvenate skin and stimulate hair growth. PRP utilises the healing properties of a patient's own blood plasma, which is rich in growth factors. For facial rejuvenation, PRP can improve skin texture, reduce fine lines, and promote overall skin health. In hair restoration, PRP stimulates dormant hair follicles, promoting hair thickness and growth. This natural approach to regeneration can provide long-lasting improvements with minimal risk of allergic reactions.",
        procedure: "A small amount of blood is drawn from the patient and processed in a centrifuge to separate the platelet-rich plasma. For facial rejuvenation, the PRP is injected into specific areas of the face using a fine needle or applied with microneedling. For hair restoration, it's injected into the scalp. The procedure typically takes 60-90 minutes.",
        aftercare: "Avoid washing the treated area for 24 hours. For facial treatments, avoid makeup for 24 hours. For scalp treatments, avoid hair products for 24 hours. Refrain from intense exercise for 24-48 hours. Mild swelling or bruising may occur. For optimal results, a series of treatments is usually recommended, typically 3-4 sessions spaced 4-6 weeks apart.",
        "post-care": "Continue to avoid harsh skincare products or treatments for 1 week. Use gentle, non-irritating skincare products. Protect the treated area from sun exposure and use a broad-spectrum sunscreen daily. For hair treatments, avoid chemical treatments (colouring, perming) for at least 2 weeks. Maintain a healthy diet rich in proteins and vitamins to support the regenerative process. Follow-up treatments may be recommended after 6-12 months to maintain results.",
        image: "/Images/PRP:PLASMAhairrestoration.jpeg"
      },
      {
        name: "SEMI-PERMANENT TATTOO",
        info: "Long-lasting makeup solutions for eyes, brows, and lips. Semi-permanent makeup, also known as cosmetic tattooing or micropigmentation, involves implanting pigment into the upper layers of the skin to enhance facial features. This includes microblading for eyebrows, which creates hair-like strokes for a natural look, eyeliner tattoos to define the eyes, and lip blushing for enhanced lip color and definition. Results can last 1-3 years, depending on the area treated and individual factors.",
        procedure: "The area is cleansed and a topical anesthetic is applied. For microblading, a handheld tool with tiny needles is used to create hair-like strokes in the brows. For eyeliner or lip procedures, a digital machine with a single needle is typically used. Pigment is implanted into the upper layers of the skin. The procedure can take 1-3 hours depending on the area treated.",
        aftercare: "Keep the area dry for 7-10 days, avoiding water, sweat, and makeup. Apply any provided aftercare ointment as directed. Avoid picking or scratching the treated area. Protect from direct sunlight. Some flaking and color fading is normal during the healing process. A touch-up session is usually needed after 4-6 weeks to perfect the results. Avoid swimming, saunas, and intense workouts for 2 weeks. For lip treatments, avoid spicy foods and smoking for several days."
      }
    ]
  },
  "FACE": {
    image: "/Images/faces.jpg",
    summary: "Our face treatments cover a wide range of services to rejuvenate, tighten, and enhance your facial features.",
    treatments: [
      { 
        name: "CHEMICAL PEELS",
        info: "Exfoliating treatments to improve skin texture and tone, addressing issues such as fine lines, uneven pigmentation, and mild scarring.",
        procedure: "The skin is cleansed and a chemical solution is applied. The solution causes the outer layer of skin to peel off over the following days, revealing fresher, younger-looking skin underneath.",
        aftercare: "Avoid sun exposure and use high SPF sunscreen. Do not pick or peel skin. Use gentle, non-irritating skincare products. Follow-up treatments may be recommended every 4-6 weeks."
      },
      { 
        name: "GREEN PEEL",
        info: "Herbal peeling treatment for skin regeneration and rejuvenation, suitable for various skin types.",
        procedure: "A mixture of herbs is massaged into the skin, causing mild exfoliation. The treatment is left on for several hours before being removed. The skin will peel naturally over the next few days.",
        aftercare: "Keep the skin hydrated. Avoid sun exposure and use high SPF sunscreen. Do not use exfoliating products for at least a week. Follow post-treatment skincare routine as advised."
      },
      { 
        name: "MICRONEEDLING",
        info: "Collagen induction therapy to improve skin texture, reduce fine lines, and address scars.",
        procedure: "A device with fine needles is rolled or stamped over the skin, creating tiny punctures. This stimulates the skin's natural healing process and collagen production.",
        aftercare: "Avoid touching the treated area for 24 hours. Use gentle, non-irritating skincare products. Avoid sun exposure and wear high SPF sunscreen. Do not apply makeup for 24-48 hours."
      },
      { 
        name: "HYDRAFACIAL",
        info: "Multi-step facial treatment for deep cleansing, exfoliation, extraction, and hydration.",
        procedure: "The treatment involves cleansing, exfoliation, extraction, hydration, and protection steps. A special device is used to deliver serums deep into the skin.",
        aftercare: "Avoid sun exposure and wear high SPF sunscreen. Do not use harsh skincare products for 48 hours. Stay hydrated and avoid heavy makeup for 24 hours."
      },
      { 
        name: "LED LIGHT THERAPY",
        info: "Non-invasive treatment using different light wavelengths for various skin concerns like acne, inflammation, and aging.",
        procedure: "The patient lies under an LED light panel for about 20 minutes. Different colors of light are used depending on the specific skin concern being addressed.",
        aftercare: "No specific aftercare is required. Continue with your regular skincare routine. For best results, multiple sessions are usually recommended."
      },
      { 
        name: "VAMPIRE FACIAL",
        info: "Combines microneedling with PRP (Platelet-Rich Plasma) for enhanced skin rejuvenation.",
        procedure: "Blood is drawn and processed to extract PRP. Microneedling is performed, followed by application of the PRP to the treated area.",
        aftercare: "Avoid touching the treated area for 24 hours. Use gentle, non-irritating skincare products. Avoid sun exposure and wear high SPF sunscreen. Do not apply makeup for 24-48 hours."
      },
      { 
        name: "SCULPTRA",
        info: "Collagen-stimulating injectable for gradual, natural-looking volume restoration, lasting up to two years.",
        procedure: "The poly-L-lactic acid solution is injected into targeted areas of the face. Multiple treatments over several months may be needed for optimal results.",
        aftercare: "Massage the treated area for 5 minutes, 5 times a day, for 5 days. Avoid excessive sun exposure. Stay hydrated. Results develop gradually over several months."
      },
      { 
        name: "NASOLABIAL FOLDS TREATMENT",
        info: "Targeted filler treatment for smile lines running from the nose to the corners of the mouth.",
        procedure: "After applying a topical anesthetic, dermal filler is carefully injected along the nasolabial folds to soften their appearance.",
        aftercare: "Avoid touching or massaging the treated area for 24 hours. Apply cold compresses if swelling occurs. Avoid strenuous exercise for 24 hours.",
        image: "/Images/NASOLABIALFOLDSTREATMENT.jpeg"
      },
      { 
        name: "MARIONETTE LINE TREATMENT",
        info: "Filler treatment for downturned corners of the mouth, often associated with a sad or angry expression.",
        procedure: "Dermal filler is precisely injected into the lines that run from the corners of the mouth down towards the chin.",
        aftercare: "Avoid excessive facial movements for 24 hours. Apply cold compresses if swelling occurs. Avoid alcohol and blood-thinning medications for 24 hours."
      },
      { 
        name: "CHEEK AUGMENTATION",
        info: "Dermal filler treatment to enhance cheek volume and contour, improving overall facial harmony.",
        procedure: "After applying a topical anesthetic, dermal filler is strategically injected into the cheek area to add volume and enhance contours.",
        aftercare: "Avoid touching or applying pressure to the treated area for 6 hours. Use cold compresses to reduce swelling. Avoid strenuous exercise and alcohol for 24 hours."
       
      },
    ]
  },
  "EYES": {
    image: "/Images/eyes.jpg",
    summary: "Enhance and rejuvenate your eye area with our specialised treatments.",
    treatments: [
      {
        name: "TEAR THROUGH",
        info: "Dermal filler treatment to reduce under-eye hollows and dark circles, creating a more rested and youthful appearance.",
        procedure: "After applying a topical anesthetic, a small amount of hyaluronic acid filler is carefully injected into the tear trough area using a fine needle or cannula. The filler is then gently massaged to ensure even distribution.",
        aftercare: "Apply cold compresses to reduce swelling. Avoid touching or massaging the treated area for 24 hours. Sleep with your head elevated for the first night. Avoid strenuous exercise for 24-48 hours.",
        image: "/Images/tearthrough.jpeg"
      },
      {
        name: "SUNEKOS FOR EYES",
        info: "Specialised eye treatment combining amino acids and hyaluronic acid to stimulate collagen and elastin production, improving skin quality and reducing fine lines.",
        procedure: "The Sunekos solution is injected into the periorbital area using a very fine needle. Multiple small injections are administered to ensure even distribution. The treatment typically takes about 15-20 minutes.",
        aftercare: "Avoid touching or applying makeup to the treated area for 12 hours. Stay out of direct sunlight and avoid intense heat for 1 week. Mild swelling or bruising may occur but should subside quickly."
      },
      {
        name: "LUMI EYES",
        info: "Brightening and refreshing treatment for the eye area, addressing dark circles, puffiness, and fine lines for a more awake appearance.",
        procedure: "The treatment may involve a combination of techniques such as light chemical peels, microneedling, and the application of specialized serums. The exact procedure can be customized based on individual needs.",
        aftercare: "Keep the eye area clean and avoid rubbing or touching. Use gentle, non-irritating eye products. Protect the area from sun exposure and use a high SPF sunscreen. Follow any specific instructions provided by your practitioner.",
        image: "/Images/lumieyes.jpeg"
      },
      {
        name: "PRP FOR EYES",
        info: "Platelet-Rich Plasma therapy to improve skin texture around the eyes, stimulating collagen production and cellular renewal.",
        procedure: "A small amount of blood is drawn and processed to extract the platelet-rich plasma. The PRP is then injected into the periorbital area using a fine needle or applied topically in conjunction with microneedling.",
        aftercare: "Apply cold compresses to reduce swelling. Avoid makeup for 24 hours. Protect the area from sun exposure. Mild swelling or bruising may occur but should subside within a few days."
      },
      {
        name: "MICRONEEDLING FOR EYES",
        info: "Collagen induction therapy for the delicate eye area, improving the appearance of fine lines, wrinkles, and overall skin texture.",
        procedure: "A device with fine needles is gently rolled or stamped over the skin around the eyes, creating tiny punctures. This stimulates the skin's natural healing process and collagen production.",
        aftercare: "Avoid touching the treated area for 24 hours. Use only recommended skincare products. Protect the area from sun exposure and wear high SPF sunscreen. Avoid applying makeup for 24-48 hours."
      },
      {
        name: "PROJULO FOR EYES",
        info: "Advanced hydrating and revitalising treatment for the eye area, combining hyaluronic acid with other active ingredients to improve skin quality and reduce signs of aging.",
        procedure: "The Projulo solution is injected into the periorbital area using a very fine needle. The treatment may involve multiple small injections to ensure even distribution. The procedure typically takes about 20-30 minutes.",
        aftercare: "Avoid touching or applying makeup to the treated area for 12 hours. Stay hydrated and avoid excessive sun exposure. Mild swelling may occur but should subside quickly."
      },
      {
        name: "FOXI EYES",
        info: "PDO Thread lift for a subtle lift in the outer corners of the eyes, creating a more open and alert appearance.",
        procedure: "After applying local anesthesia, fine PDO threads are inserted under the skin near the outer corners of the eyes using a thin cannula. The threads are then positioned to create a lifting effect.",
        aftercare: "Avoid rubbing or massaging the treated area for 1-2 weeks. Sleep with your head elevated for a few nights. Avoid high-impact exercises for 1-2 weeks. Some swelling and bruising is normal and should subside within a week.",
        image:"/Images/Foxy-eyePDOcogthreadlift.jpeg",
      },
      {
        name: "MICROBLADING",
        info: "Semi-permanent eyebrow enhancement technique that creates natural-looking, hair-like strokes for fuller, more defined brows.",
        procedure: "After numbing the area, a handheld tool with tiny needles is used to create fine, hair-like strokes in the brow area. Pigment is deposited into these strokes to create the appearance of fuller brows.",
        aftercare: "Keep the area dry for 7-10 days. Avoid touching or scratching the treated area. Protect from direct sunlight. Apply any provided aftercare ointment as directed. A touch-up session may be needed after 4-6 weeks."
      },
      {
        name: "EYELINER TATTOO",
        info: "Long-lasting eyeliner application that enhances eye definition and eliminates the need for daily eyeliner application.",
        procedure: "After numbing the eyelid area, pigment is implanted along the lash line using a specialised tattoo machine. The thickness and style of the line can be customised to the client's preference.",
        aftercare: "Keep the area clean and avoid touching. Apply any provided aftercare ointment as directed. Avoid eye makeup for 7-10 days. Protect the area from sun exposure. Some swelling is normal and should subside within a few days."
      },
      {
        name: "MICROSHADING (POWDER BROWS)",
        info: "Soft, powder-filled brow look for a fuller appearance, ideal for those who prefer a more filled-in brow style.",
        procedure: "After numbing the area, a machine is used to create tiny dots of pigment in the brow area, resulting in a soft, shaded appearance. The intensity and shape can be customized to the client's preference.",
        aftercare: "Keep the area dry for 7-10 days. Avoid touching or scratching the treated area. Protect from direct sunlight. Apply any provided aftercare ointment as directed. A touch-up session may be needed after 4-6 weeks."
      },
    ]
  },
  "LIPS": {
    image: "/Images/lips2.jpg",
    summary: "Enhance your lips with our range of filler and semi-permanent makeup options.",
    treatments: [
      {
        name: "RUSSIAN LIPS",
        info: "Technique for heart-shaped lip enhancement with a defined cupid's bow. This method creates a distinctly shaped upper lip with a prominent cupid's bow and subtle lower lip fullness, resulting in a doll-like appearance.",
        procedure: "After applying a topical anesthetic, hyaluronic acid filler is carefully injected into specific points of the lips, focusing on creating vertical height rather than horizontal volume. The filler is placed in small amounts and layered to achieve the desired shape.",
        aftercare: "Apply ice packs to reduce swelling. Avoid excessive heat and strenuous exercise for 24-48 hours. Don't massage or press on the lips for at least a week. Stay hydrated and avoid alcohol for 24 hours. Some swelling is normal and should subside within a few days."
      },
      {
        name: "HD LIPS",
        info: "High-definition lip contouring and volumising treatment. This advanced technique uses precise filler placement to enhance lip shape, volume, and definition for a bold, sculpted look.",
        procedure: "The practitioner carefully maps out the lip area to plan the injection points. After applying a topical anesthetic, dermal filler is strategically injected to enhance the lip borders, add volume, and create definition. Multiple entry points may be used to achieve the desired contour and fullness.",
        aftercare: "Avoid touching or applying pressure to the lips for 6 hours. Use cold compresses to reduce swelling. Avoid excessive heat, alcohol, and strenuous exercise for 24 hours. Don't use lip products for 24 hours. Some bruising and swelling is normal and should resolve within a week."
      },
      {
        name: "GLOW LIPS",
        info: "Subtle lip enhancement for a natural, glossy look. This treatment uses a specialised filler or combination of products to add subtle volume and hydration, resulting in a natural, 'your-lips-but-better' appearance.",
        procedure: "After applying a topical anesthetic, a small amount of specialized filler is injected into the lips using a fine needle or cannula. The filler is carefully distributed to enhance lip hydration and add a subtle volume boost. The focus is on creating a natural, plump look rather than dramatically changing the lip shape.",
        aftercare: "Apply cold compresses to reduce swelling. Avoid hot foods and drinks for 24 hours. Don't use lip products for 24 hours. Stay hydrated and avoid alcohol for 24 hours. Some mild swelling is normal and should subside quickly."
      },
      {
        name: "LIPS LINER TATTOO",
        info: "Semi-permanent makeup to define lip contours. This technique applies pigment along the lip line to enhance definition and can create the illusion of fuller lips. It's ideal for those looking to improve lip symmetry or definition without adding volume.",
        procedure: "The lip area is cleaned and a topical anesthetic is applied. The practitioner then uses a specialized tattoo machine to implant pigment along the lip line. The color and width of the line can be customized to the client's preference. The procedure typically takes 1-2 hours.",
        aftercare: "Keep the lips moisturized with the provided aftercare ointment. Avoid picking or scratching the treated area. Don't use lip products for 7-10 days. Protect the area from sun exposure. Avoid hot, spicy foods and smoking for several days. A touch-up session may be needed after 4-6 weeks."
      },
      {
        name: "FULL LIPS TATTOO",
        info: "Complete lip color enhancement with semi-permanent makeup. This treatment applies pigment across the entire lip area, enhancing color and creating the appearance of fuller, more defined lips. It's perfect for those wanting long-lasting colour and definition.",
        procedure: "After applying a topical anesthetic, the practitioner uses a specialised tattoo machine to implant pigment across the entire lip area. The colour is customised to the client's preference, and can range from a natural lip tone to a more vibrant colour. The procedure typically takes 2-3 hours.",
        aftercare: "Apply the provided aftercare ointment regularly to keep lips moisturised. Avoid picking or scratching the treated area. Don't use lip products for 7-10 days. Protect the lips from sun exposure. Avoid hot, spicy foods and smoking for several days. Expect some swelling and intensity of color which will subside. A touch-up session is usually needed after 4-6 weeks."
      },
    ]
  },
  "HANDS REJUVENATION": {
    image: "/Images/HANDSREJUVENATION.jpg",
    summary: "Restore youthful appearance to your hands with our specialised treatments.",
    treatments: [
      {
        name: "SUNEKOS FOR HANDS",
        info: "Skin rejuvenation treatment to improve hand skin quality. Sunekos combines hyaluronic acid with a patented formula of amino acids to stimulate the production of collagen and elastin, improving skin texture, hydration, and overall appearance of the hands.",
        procedure: "The hands are cleansed and disinfected. The Sunekos solution is then injected into multiple points across the back of the hands using a very fine needle. The treatment typically takes about 20-30 minutes. A series of treatments, usually 3-4 sessions spaced 7-10 days apart, is recommended for optimal results.",
        aftercare: "Avoid washing your hands for 4 hours after treatment. Don't engage in strenuous activities or sports for 24 hours. Avoid extreme temperatures (hot showers, saunas, etc.) for 24 hours. Mild swelling or bruising may occur but should subside quickly. Maintain good hand hygiene and use a high SPF sunscreen on the treated areas."
      },
      {
        name: "PRP FOR HANDS",
        info: "Platelet-Rich Plasma therapy to rejuvenate hand skin. This treatment uses growth factors from your own blood to stimulate collagen production, improve skin texture, and reduce the appearance of veins and tendons, resulting in younger-looking hands.",
        procedure: "A small amount of blood is drawn from the patient and processed in a centrifuge to isolate the platelet-rich plasma. The hands are cleansed, and the PRP is then injected into multiple points across the back of the hands. The procedure typically takes about 45-60 minutes. A series of treatments, usually 3 sessions spaced 4-6 weeks apart, is often recommended.",
        aftercare: "Apply cold compresses to reduce swelling if needed. Avoid washing your hands for 4 hours after treatment. Don't engage in activities that may irritate the skin for 24 hours. Protect your hands from sun exposure and use a high SPF sunscreen. Some mild swelling or bruising may occur but should resolve within a few days."
      },
      {
        name: "MICRONEEDLING FOR HANDS",
        info: "Collagen induction therapy to improve hand skin texture. This treatment uses fine needles to create micro-injuries in the skin, stimulating natural collagen and elastin production. It can improve the appearance of fine lines, wrinkles, and overall skin texture on the hands.",
        procedure: "The hands are cleansed and a topical anesthetic may be applied. A microneedling device is then moved across the skin of the hands, creating tiny punctures. The procedure typically takes about 30-45 minutes. For enhanced results, a nourishing serum may be applied during or after the treatment. A series of treatments, usually 3-6 sessions spaced 4-6 weeks apart, is often recommended.",
        aftercare: "Keep the treated area clean and avoid touching with unwashed hands. Apply any recommended post-treatment products. Avoid sun exposure and use a high SPF sunscreen. Don't use harsh skincare products on the hands for at least 5 days. Mild redness and sensitivity are normal and should subside within a few days."
      },
      {
        name: "PROJULO FOR HANDS",
        info: "Advanced hydrating treatment for hands. Projulo combines hyaluronic acid with other active ingredients to deeply hydrate the skin, improve elasticity, and reduce the appearance of fine lines and wrinkles on the hands.",
        procedure: "The hands are cleansed and prepared for treatment. The Projulo solution is then injected into multiple points across the back of the hands using a very fine needle or cannula. The treatment typically takes about 20-30 minutes. The number of treatments needed can vary, but often a series of 2-3 treatments spaced 2-4 weeks apart is recommended.",
        aftercare: "Avoid washing your hands for 4 hours after treatment. Don't engage in activities that may irritate the skin for 24 hours. Avoid extreme temperatures for 24 hours. Use a gentle moisturizer and high SPF sunscreen on the treated areas. Some mild swelling may occur but should subside quickly. Maintain good hand hygiene to protect the treated skin."
      },
    ]
  },
  "HAIR": {
    image: "/Images/HAIRRESTORATION.jpg",
    summary: "Restore and rejuvenate your hair with our specialised treatment.",
    treatments: [
      {
        name: "PRP FOR HAIR RESTORATION",
        info: "Platelet-Rich Plasma (PRP) therapy is an innovative treatment that uses your body's own growth factors to stimulate hair growth and improve hair thickness. This treatment is effective for both men and women experiencing hair thinning or loss. PRP can help to strengthen existing hair follicles, slow down hair loss, and promote the growth of new hair. It's particularly beneficial for individuals with androgenetic alopecia (male/female pattern baldness) or those looking to improve overall hair density and quality.",
        procedure: "The treatment begins with drawing a small amount of blood from the patient, typically from the arm. This blood is then processed in a centrifuge to separate the platelet-rich plasma. After cleansing the scalp, the PRP is injected into multiple points across the treatment area using a very fine needle. The growth factors in the PRP stimulate dormant hair follicles and support the health of existing follicles. The entire procedure typically takes about 60-90 minutes. For optimal results, a series of treatments is usually recommended, typically 3-4 sessions spaced 4-6 weeks apart, followed by maintenance treatments every 4-6 months.",
        aftercare: "1. Avoid washing your hair for at least 24 hours after the treatment.\n2. When you do wash your hair, use lukewarm water and a gentle shampoo.\n3. Avoid strenuous exercise, saunas, and steam rooms for 2-3 days post-treatment.\n4. Don't use any harsh hair products or treatments (like coloring or perming) for at least a week.\n5. Avoid direct sunlight on the treated area and wear a hat if you'll be in the sun.\n6. Some mild swelling or redness in the treated area is normal and should subside within a day or two.\n7. If you experience any unusual pain or symptoms, contact your practitioner.",
        "post-care": "1. Continue to be gentle with your hair and scalp for the first week after treatment.\n2. Maintain a healthy diet rich in proteins, vitamins, and minerals to support hair growth.\n3. Stay hydrated by drinking plenty of water.\n4. Use any prescribed or recommended hair care products consistently.\n5. Avoid excessive heat styling and harsh chemical treatments.\n6. Consider using a silk pillowcase to reduce friction on your hair while sleeping.\n7. Attend all follow-up appointments and maintenance sessions as recommended by your practitioner.\n8. Be patient - noticeable results typically take 3-6 months as the hair growth cycle is slow.\n9. Take progress photos monthly to track your results.\n10. Consult with your practitioner about complementary treatments or medications that might enhance your results.",
        image:"/Images/PRPFORHAIRRESTORATION.jpg"
      }
    ]
  },
};

const beforeAfterImages = [
  { src: "/Images/frownremoved2.jpg", caption: "Anti-Wrinkle Injection (B-O-T-O-X" },
  { src: "/Images/surgery.jpg", caption: "Cheek Contouring" },
  { src: "/Images/skinbooster.jpg", caption: "Jwaline Contouring" },
  { src: "/Images/skinboosternew.jpg", caption: "Tear Through Treatment" },
];



function ServicesPage() {
  const [selectedTreatment, setSelectedTreatment] = useState(null);

  return (
    <PageWrapper>
      <MainContent>
        <PageTitle>Our Professional Treatments</PageTitle>
        {Object.entries(services).map(([category, { image, summary, treatments }]) => (
          <CategorySection key={category}>
            <CategoryHeader>
              <CategoryImage src={image} alt={category} />
              <CategoryTitle>{category}</CategoryTitle>
            </CategoryHeader>
            <CategorySummaryWrapper>
              <CategorySummary>{summary}</CategorySummary>
            </CategorySummaryWrapper>
            <TreatmentGrid>
              {treatments.map((treatment, index) => (
                <TreatmentCard key={index}>
                  <TreatmentTitle>{treatment.name}</TreatmentTitle>
                  <TreatmentDetails>
                    <TreatmentInfo>{getFirstSentence(treatment.info)}</TreatmentInfo>
                    <LearnMoreButton onClick={() => setSelectedTreatment(treatment)}>
                      Learn More
                    </LearnMoreButton>
                  </TreatmentDetails>
                </TreatmentCard>
              ))}
            </TreatmentGrid>
          </CategorySection>
        ))}

        <BeforeAfterSection>
          <BeforeAfterTitle>Treatment Results</BeforeAfterTitle>
          <BeforeAfterGrid>
            {beforeAfterImages.map((image, index) => (
              <BeforeAfterCard key={index}>
                <BeforeAfterImage src={image.src} alt={`Before and After - ${image.caption}`} />
                <BeforeAfterCaption>{image.caption}</BeforeAfterCaption>
              </BeforeAfterCard>
            ))}
          </BeforeAfterGrid>
        </BeforeAfterSection>

        {selectedTreatment && (
          <Modal
            isOpen={!!selectedTreatment}
            onClose={() => setSelectedTreatment(null)}
            treatment={selectedTreatment}
          />
        )}
      </MainContent>
    </PageWrapper>
  );
}

export default ServicesPage;