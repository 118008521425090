import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaInstagram, FaTiktok, FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

// Main footer wrapper
const FooterWrapper = styled.footer`
  background-color: #D4C8B0;
  color: #333;
  padding: 2rem 5%;
`;

// Grid layout for footer content
const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

// Styling for each footer section
const FooterSection = styled.div`
&:first-child {
  margin-right: 2rem;
}

h3 {
  font-family: 'Playfair Display', serif;
  color: #7C6A46;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  width: 100%;
}
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  
  svg {
    margin-right: 0.5rem;
    color: #7C6A46;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

// Styling for social media links
const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SocialLink = styled.a`
  color: #7C6A46;
  font-size: 1.6rem;
  transition: color 0.3s ease;

  &:hover {
    color: #333;
  }
`;

// Styling for opening hours table
const HoursTable = styled.table`
  width: 100%;
`;

const HoursRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const HoursDay = styled.td`
  padding: 0.5rem 0;
`;

const HoursTime = styled.td`
  padding: 0.5rem 0;
  text-align: right;
`;

// Styling for copyright text
const Copyright = styled.p`
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #E8DFD0;
`;

function Footer() {
  return (
    <FooterWrapper>
      <FooterContent>
        {/* Contact Us section */}
        <FooterSection>
          <h3>Contact Us</h3>
          <ContactInfo>
            <FaMapMarkerAlt />
            <span>28 Barking Rd, London E16 1EQ</span>
          </ContactInfo>
          <ContactInfo>
            <FaPhone />
            <span>07300108208</span>
          </ContactInfo>
          <ContactInfo>
            <FaEnvelope />
            <span>Londonlabeauty@gmail.com</span>
          </ContactInfo>
        </FooterSection>
        
        {/* Follow Us section */}
        <FooterSection>
          <h3>Follow Us</h3>
          <SocialLinks>
            <SocialLink href="https://www.facebook.com/p/London-la-beauty-100063829913713/" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/londonlabeauty/" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </SocialLink>
            <SocialLink href="https://www.tiktok.com/@londonlabeauty" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </SocialLink>
          </SocialLinks>
        </FooterSection>

        {/* Opening Hours section */}
        <FooterSection>
          <h3>Opening Hours</h3>
          <HoursTable>
            <tbody>
              <HoursRow>
                <HoursDay>Monday</HoursDay>
                <HoursTime>10:00 - 20:00</HoursTime>
              </HoursRow>
              <HoursRow>
                <HoursDay>Tuesday</HoursDay>
                <HoursTime>10:00 - 20:00</HoursTime>
              </HoursRow>
              <HoursRow>
                <HoursDay>Wednesday</HoursDay>
                <HoursTime>10:00 - 20:00</HoursTime>
              </HoursRow>
              <HoursRow>
                <HoursDay>Thursday</HoursDay>
                <HoursTime>10:00 - 20:00</HoursTime>
              </HoursRow>
              <HoursRow>
                <HoursDay>Friday</HoursDay>
                <HoursTime>10:00 - 20:00</HoursTime>
              </HoursRow>
              <HoursRow>
                <HoursDay>Saturday</HoursDay>
                <HoursTime>09:00 - 19:00</HoursTime>
              </HoursRow>
              <HoursRow>
                <HoursDay>Sunday</HoursDay>
                <HoursTime>11:00 - 17:00</HoursTime>
              </HoursRow>
            </tbody>
          </HoursTable>
        </FooterSection>
      </FooterContent>
      <Copyright>&copy; 2024 London La Beauty. All rights reserved.</Copyright>
    </FooterWrapper>
  );
}

export default Footer;