import React from 'react';
import HeroSection from '../components/HeroSection';
import FeaturedServices from '../components/FeaturedServices';
import TestimonialsCarousel from '../components/TestimonialsCarousel';
import ClinicIntroduction from '../components/ClinicIntroduction';
import TreatmentGrid from '../components/TreatmentGrid';

function Home() {
  return (
    <div className="home">
      <HeroSection />
      <ClinicIntroduction/>
 
      <TreatmentGrid/>
      <hr className="section-divider" />
      <FeaturedServices />
      <hr className="section-divider" />
      <TestimonialsCarousel />
   
    </div>
  );
}

export default Home;