import React, { useEffect } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 10px;
  max-width: 650px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0,0,0 0.5);
  border: none;
  border-radius:50%;
  width:30px;
  height: 30px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const ModalImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: contain;
  border-radius: 10px 10px 0 0;
`;

const ModalBody = styled.div`
  padding: 2rem;
`;

const ModalHeader = styled.div`
  margin-bottom: 1rem;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  color: #333;
`;

const ModalSection = styled.div`
  margin-bottom: 1.5rem;
`;

const ModalSectionTitle = styled.h3`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const ModalDescription = styled.p`
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
`;

function Modal({ isOpen, onClose, treatment }) {
  useEffect(() => {
    console.log('Treatment data received in Modal:', treatment);
  }, [treatment]);

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {treatment.image && <ModalImage src={treatment.image} alt={treatment.name} />}
        <ModalBody>
          <ModalHeader>
            <ModalTitle>{treatment.name}</ModalTitle>
          </ModalHeader>
          <ModalSection>
            <ModalSectionTitle>Description</ModalSectionTitle>
            <ModalDescription>{treatment.info}</ModalDescription>
          </ModalSection>
          <ModalSection>
            <ModalSectionTitle>Procedure</ModalSectionTitle>
            <ModalDescription>{treatment.procedure}</ModalDescription>
          </ModalSection>
          <ModalSection>
            <ModalSectionTitle>Aftercare</ModalSectionTitle>
            <ModalDescription>{treatment.aftercare}</ModalDescription>
          </ModalSection>
          {treatment['post-care'] && (
            <ModalSection>
              <ModalSectionTitle>Post-Care</ModalSectionTitle>
              <ModalDescription>{treatment['post-care']}</ModalDescription>
            </ModalSection>
          )}
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
}

export default Modal;