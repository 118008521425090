import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaQuoteLeft } from 'react-icons/fa';

const TestimonialsSection = styled.section`
  background-color: #f8f9fa;
  padding: 4rem 0;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 3rem;
    font-family: 'Playfair Display', serif;
    @media (max-width: 768px) {
      font-size: 2rem;
    }
`;

const TestimonialCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);;
  padding: 1.5rem;
  margin: 0 0.5rem;
  height: 200px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    padding: 1rem;
    min-height: 180px;
  }
`;

const QuoteIcon = styled(FaQuoteLeft)`
  font-size: 1.5rem;
  color:#8B7D5B;
  margin-bottom: 0.5rem;
`;

const Quote = styled.blockquote`
font-style: italic;
font-size: 1rem;
color: #555;
margin-bottom: 0.5rem;
flex-grow: 1;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;

@media (max-width: 768px) {
  font-size: 0.9rem;
  -webkit-line-clamp: 3;
}
`;

const Author = styled.p`
  font-weight: bold;
  color: #333;
  font-size: 0.9rem;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

function TestimonialsCarousel() {
  const testimonials = [
    {
        quote: "I'm very happy with my treatments, I have done so many treatment with a big doctors but no one like Suzan have worked professionally in details with full concentration with me and the work. She is putting her heart in her work. Well done to her and I will be back for all my future treatment 5*",
        author: "ollgapower play adopt me Tahier"
      },
      {
        quote: "had a fantastic first appointment (Botox) and will definitely be returning for more touch-ups. Suzan is kind, knowledgeable, and professional; all great 😊😉 I had to reschedule my original appointment with just a day's notice and she was …More",
        author: "Emy Shah"
      },
      {
        quote: "I have been to London LA Beauty for many treatments, one of them is plasma for the hair and face, results are amazing I am soo satisfied and highly recommended Suzan, she is so professional and accurate in her working.",
        author: "gyanaikam"
      },
      {
        quote: "I had my deep forehead lines done by Suzan. The whole process was so professional and pain free. I am so greatful for her excellent work. I look so much better and much younger already!! I highly recommend her X",
        author: "rana salman"
      },
      {
        quote: "Susan is a lovely person and explaines all procedures before hand made to feel very comfortable and I am very happy with the treatments I have had with her I would recommend and I will continue to go to this salon for further treatments",
        author: "Terisa Johnson"
      },
      {
        quote: "I want to thank you for the beautiful natural filler on my lips and the botox for my for head ams between my eys brow all my wrinkles done now . I like it and it is nice, you did amazing job for me not any bruse or swollen ether 💟👌👍😊💓",
        author: "Kurda Muhamad"
      },
      {
        quote: "Suzan is fantastic. Very professional. Takes time to make sure you get great results. Highly recommend",
        author: "Shannon Griffin"
      },
      {
        quote: "Thank you so much for the treatment, you are the best one I'm so happy with the Result thank you for this job that you did for me",
        author: "Awrin"
      },
    ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <TestimonialsSection>
      <SectionTitle>What Our Clients Say</SectionTitle>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index}>
            <QuoteIcon />
            <Quote>{testimonial.quote}</Quote>
            <Author>- {testimonial.author}</Author>
          </TestimonialCard>
        ))}
      </Slider>
    </TestimonialsSection>
  );
}

export default TestimonialsCarousel;