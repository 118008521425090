import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ServicesWrapper = styled.section`
  padding: 4rem 5%;
  background-color: #f8f9fa;
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  color: #333;
  font-family: 'Playfair Display', serif;
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const ServiceCard = styled.div`
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
`;

const ServiceContent = styled.div`
  padding: 1.5rem;
`;

const ServiceTitle = styled.h3`
  margin-bottom: 1rem;
  color: #333;
  font-size: 1.3rem;
`;

const ServiceDescription = styled.p`
  color: #666;
  margin-bottom: 1rem;
  line-height: 1.6;
`;

const ReadMoreLink = styled(Link)`
  color: #8B7D5B;
  text-decoration: none;
  font-weight: 500;
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid #8B7D5B;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #8B7D5B;
    color: white;
  }
`;

const services = [
  { id: '01', title: 'DERMAL FILLERS', description: "Injectable treatments to restore volume, smooth lines, and enhance facial contours.", image: '/Images/prp-treatment.jpg', link: '/services' },
  { id: '02', title: 'ANTI-WRINKLE INJECTION', description: "Botox treatments to reduce the appearance of fine lines and wrinkles.", image: '/Images/ANTI-WRINKLEINJECTION.jpg', link: '/services' },
  { id: '03', title: 'PDO THREAD', description: 'Non-surgical facelift and skin tightening using dissolvable threads.', image: '/Images/PDOTHREAD.jpg', link: '/services' },
  { id: '04', title: 'SUNEKOS', description: 'Advanced skin rejuvenation treatment combining amino acids and hyaluronic acid.', image: '/Images/SUNEKOS.jpg', link: '/services' },
  { id: '05', title: 'PROFHILO', description: 'Innovative hyaluronic acid treatment for skin laxity and hydration.', image: '/Images/PROFHILO.jpg', link: '/services' },
  { id: '06', title: 'SKIN BOOSTER', description: "Various treatments to improve skin hydration, texture, and overall quality.", image: '/Images/SKINBOOSTER2.jpg', link: '/services' },
  { id: '07', title: 'PRP FACE/HAIR RESTORATION', description: "Platelet-Rich Plasma therapy to rejuvenate skin and stimulate hair growth.", image: '/Images/HAIRRESTORATION.jpg', link: '/services' },
  { id: '08', title: 'SEMI-PERMANENT TATTOO', description: "Long-lasting makeup solutions for eyes, brows, and lips.", image: '/Images/SEMI-PERMANENTTATTOO.jpg', link: '/services' },
];

function ServicesGrid() {
  return (
    <ServicesWrapper>
      <SectionTitle>Our Services</SectionTitle>
      <ServiceGrid>
        {services.map((service) => (
          <ServiceCard key={service.id}>
            <ServiceImage src={service.image} alt={service.title} />
            <ServiceContent>
              <ServiceTitle>{service.id} {service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
              <ReadMoreLink to="/services">Read more</ReadMoreLink>
            </ServiceContent>
          </ServiceCard>
        ))}
      </ServiceGrid>
    </ServicesWrapper>
  );
}

export default ServicesGrid;